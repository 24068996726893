document.addEventListener('DOMContentLoaded', () => {
  const shopifyAppInit = document.getElementById('shopify-app-init')

  if (!shopifyAppInit) { return }

  var data = shopifyAppInit.dataset
  var AppBridge = window['app-bridge']
  var createApp = AppBridge.default
  window.app = createApp({
    apiKey: data.apiKey,
    host: data.host
  })

  var actions = AppBridge.actions
  var TitleBar = actions.TitleBar
  TitleBar.create(app, {
    title: data.page
  })
})
